import React from "react";
import { AddonsContainter } from "tf-checkout-react";
import { isWindowDefined } from "../events/[slug]";
import Head from "../../components/head";
import Layout from "../../components/layout";
import './addon-info.scss'

const AddOnsPage = () => {
    const onConfirmSelectionSuccess = ({
        skip_billing_page,
        event_id,
        hash,
        total,
    }) => {
        if (skip_billing_page) {
            if (isWindowDefined) {
                window.localStorage.setItem(
                    'checkoutData',
                    JSON.stringify({ hash, total }),
                )
                window.location.href = `/billing/checkout?event_id=${event_id}`
            }
        } else {
            event_id && isWindowDefined
                ? window.location.assign(
                    `/billing/billing-info?event_id=${event_id}`,
                )
                : isWindowDefined &&
                window.location.assign(
                    `/billing/billing-info`,
                )
        }
    }
    const onCountdownFinish = () => {
        if (isWindowDefined) {
            window.location.href = '/events'
        }
    }
    return (
        <>
            <Head title="Addons Page" />
            <Layout>
                <div className="addon-info billing-info">
                    <AddonsContainter
                        enableTimer={true}
                        onConfirmSelectionSuccess={onConfirmSelectionSuccess}
                        onCountdownFinish={onCountdownFinish}
                    />
                </div>
            </Layout>
        </>
    );
};

export default AddOnsPage;
